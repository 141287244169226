<template>
  <div class="common-page">
    <div class="page-header fx-vb">
      <div class="btn-act back" @click="pageBack"></div>
      <h1 class="page-title">支付成功</h1>
      <div class="btn-act"></div>
    </div>

    <main class="btn-box">
      <van-icon name="checked" size="80" color="#07c160" />
      <div class="success-tips">支付成功</div>

      <div class="back-btn" @click="pageBack">返回</div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    pageBack() {
      this.$router.go(-1);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.pay-page {
  .header {
    position: sticky;
    width: 100%;
    height: 58px;
    background: #0c0c0c;
    box-sizing: border-box;
    padding: 0 10px;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
.btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.success-tips {
  color: #ffffff;
  font-size: 24px;
  padding: 10px;
}
.back-btn {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 16px;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 30px;
  width: 80%;
}
</style>
